module.exports = (page, event, pathEvents = false) => {
  const path = pathEvents
    ? `/merchant_acquisition/flows/resellers/landing/${page}`
    : `/merchant_acquisition/flows/resellers/landing/${page}/events`;

  window.melidata('send', 'event', {
    path,
    data: { page_resource: event },
  });
};
