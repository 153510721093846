const React = require('react');
const PropTypes = require('prop-types');
const serialize = require('serialize-javascript');

const { Head } = require('nordic/head');
const { Script } = require('nordic/script');
// const { Hotjar } = require('nordic/hotjar');
const { MelidataTrack } = require('nordic/melidata/melidata-track');

const { useEffect } = React;

const QaMessage = require('../Atoms/QaMessage');
const Gtm = require('../Atoms/Gtm');
const ScriptsHead = require('../Atoms/ScriptsHead');
const { rum } = require('../../../services/utils/datadog');

const TITLE = {
  MLA: 'Convertite en revendedor Point con Mercado Pago',
  MLB: 'Ganhe dinheiro extra vendendo maquininha',
  MLC: 'Conviértete en revendedor Point con Mercado Pago',
  MLM: 'Conviertete en Representante Point con Mercado Pago.',
};

const DESCRIPTION = {
  MLA: 'Ganá dinero con Mercado Pago Point. Accedé al formulario para registrarte como Revendedor de lectores de tarjetas. Mi Mercado Pago, mi manera.',
  MLB: 'Lucre com a revenda de maquininhas e ganhe incentivos em dinheiro em até 5 dias úteis. Cadastre-se já no Programa Renda na Mão!',
  MLC: 'Ganá dinero con Mercado Pago Point. Accedé al formulario para registrarte como Revendedor de lectores de tarjetas. Mi Mercado Pago, mi manera.',
  MLM: 'Gana dinero con Mercado Pago Point, entra a formulario para registrarte como revendedor de lectores de tarjetas Point.',
};

const GTM = {
  MLA: 'GTM-KGFSTVRW',
  MLB: 'GTM-5SWBRFKD',
  MLC: 'GTM-WS4V5NQR',
  MLM: 'GTM-KKWNTGC3',
};

const Commons = (props) => {
  const { siteId = 'MLA', name, experiment, datadog } = props;

  const preloadedState = {
    ...props,
  };

  if (typeof window !== 'undefined' && window.MobileWebKit && window.MobileWebKit.topbar) {
    window.MobileWebKit.topbar.setup({
      bar_title: siteId === 'MLB' ? 'Programa de Revenda' : 'Revendedores Point',
    });
  }

  // const variantVersion = experiment?.variant || 'default';
  // const hotjarIdDefault = 491792;
  // const hotjarIdMLB = 492923;

  useEffect(() => {
    rum(datadog);
  }, []);

  return (
    <>
      <Head>
        <ScriptsHead />
        <meta content="Mercado Pago" name="author" />
        <meta content={TITLE[siteId]} property="og:title" />
        <meta content={DESCRIPTION[siteId]} name="description" />
        <meta content={DESCRIPTION[siteId]} property="og:description" />
        <title>{TITLE[siteId]}</title>
      </Head>

      <MelidataTrack
        application={{
          site_id: siteId,
        }}
        client={{ department: 'mpos' }}
        path={`/merchant_acquisition/flows/resellers/landing/${name?.toLowerCase()}`}
      />

      {/* <Hotjar
        id={siteId === 'MLB' ? hotjarIdMLB : hotjarIdDefault}
        tags={[`variant: ${variantVersion}`]}
        triggers={['launch-poll']}
      /> */}

      <Gtm id={GTM[siteId]} siteId={siteId} />

      <Script>
        {`window.__PRELOADED_STATE__ = ${serialize(preloadedState, { isJSON: true })};`}
      </Script>
      <Script src="vendor.js" />

      <QaMessage />
    </>
  );
};

Commons.propTypes = {
  experiment: PropTypes.shape({
    variant: PropTypes.string,
  }),
  name: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  translations: PropTypes.shape({}),
  datadog: PropTypes.shape({
    userId: PropTypes.number,
    version: PropTypes.string,
    service: PropTypes.string,
    applicationId: PropTypes.string,
    clientToken: PropTypes.string,
    env: PropTypes.string,
  }),
};

module.exports = Commons;
