const Cookies = require('js-cookie');

const generateCookie = (cookieName, data, expires) => {
  Cookies.set(cookieName, JSON.stringify(data), {
    expires,
  });
};

const removeCookie = (cookieName) => {
  Cookies.remove(cookieName);
};

const getCookie = (cookieName) => Cookies.get(cookieName);

module.exports = {
  generateCookie,
  getCookie,
  removeCookie,
};
