module.exports = (siteId) => {
  const LOCALES_LIST = {
    MLA: 'es-AR',
    MLB: 'pt-BR',
    MLC: 'es-CL',
    MLM: 'es-MX',
  };

  return LOCALES_LIST[siteId];
};
