const React = require('react');
const PropTypes = require('prop-types');

const { Script } = require('nordic/script');

const GTM = ({ id, experiment = null, siteId = 'MP' }) => (
  <>
    <Script>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');`}
    </Script>

    <noscript>
      <iframe
        height="0"
        src={`https://www.googletagmanager.com/ns.html?id=${id}`}
        style={{ display: 'none', visibility: 'hidden' }}
        title={id}
        width="0"
      />
    </noscript>

    <Script>
      {`window.__resellers_config = {
          experimentName: '${experiment ? experiment.name : 'default'}',
          experimentVariant: '${experiment ? experiment.variant : 'default'}',
          siteId: '${siteId}',};`}
    </Script>
  </>
);

GTM.propTypes = {
  id: PropTypes.string.isRequired,
  experiment: PropTypes.shape({
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.string,
  }),
  siteId: PropTypes.string,
};

module.exports = GTM;
