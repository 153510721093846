import { datadogRum } from '@datadog/browser-rum';
import tracking from './tracking';

export const rum = (datadogRumData = {}) => {
  const {
    userId,
    version,
    service,
    applicationId,
    clientToken,
    env,
    sessionSampleRate,
    sessionReplaySampleRate,
    experiments,
    alreadyRedirected,
  } = datadogRumData;

  if (!applicationId) {
    console.log('cannot start datadog rum');
  }

  try {
    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service,
      env,
      version,
      sessionSampleRate,
      sessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.setUser({ name: userId || 'unknown' });
    datadogRum.startSessionReplayRecording();
    datadogRum.setGlobalContextProperty('experiments', experiments || 'not_apply');
    datadogRum.setGlobalContextProperty('alreadyRedirected', alreadyRedirected || 'not_apply');

    if (experiments) {
      tracking('home', `experiments-${experiments}`);
    }

    if (alreadyRedirected) {
      tracking('home', 'already-redirected');
    }
  } catch (e) {
    console.log('cannot start datadog rum');
  }
};
