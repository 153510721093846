const React = require('react');

const scriptsHead = () => {
  const scripts = `
  <meta http-equiv="Content-Type" content="text/xml; charset=UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="cleartype" content="on"/>
  <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
  <meta property="og:type" content="website">
  <meta property="og:image:type" content="image/jpeg">
  <meta property="og:image:width" content="600">
  <meta property="og:image:height" content="315">
  <meta property="og:image" content="img/share.jpg">

  <meta name="theme-color" content="#00b1ea">
  <meta name="msapplication-navbutton-color" content="#00b1ea">
  <meta name="apple-mobile-web-app-status-bar-style" content="#00b1ea">
  <link rel="shortcut icon" href="https://secure.mlstatic.com/components/resources/mp/desktop/images/favicon.ico?v=2.9.3/favicon.ico">
  <link rel="image_src" href="https://secure.mlstatic.com/components/resources/mp/images/mercadopago-vertical-logo-95x88.png" type="image/jpeg">
  <link rel="apple-touch-icon-precomposed" href="https://secure.mlstatic.com/components/resources/mp/desktop/images/apple-touch-icon-precomposed.png?v=2.9.3/apple-touch-icon-precomposed.png">
  <link rel="apple-touch-icon-precomposed" href="https://secure.mlstatic.com/components/resources/mp/desktop/images/apple-touch-icon-72x72-precomposed.png?v=2.9.3/apple-touch-icon-72x72-precomposed.png">
  <link rel="apple-touch-icon-precomposed" href="https://secure.mlstatic.com/components/resources/mp/desktop/images/apple-touch-icon-120x120-precomposed.png?v=2.9.3/apple-touch-icon-120x120-precomposed.png">
  <link rel="apple-touch-icon-precomposed" href="https://secure.mlstatic.com/components/resources/mp/desktop/images/apple-touch-icon-144x144-precomposed.png?v=2.9.3/apple-touch-icon-144x144-precomposed.png">
`;

  return <div dangerouslySetInnerHTML={{ __html: scripts }} />;
};

module.exports = scriptsHead;
